import Vue from "vue";
import Antd, { message } from "ant-design-vue";
import 'ant-design-vue/dist/antd.css';
import VueRouter from "vue-router";
import setting from './settings'
// @ts-ignore
// import {ACCESS_TOKEN}  from '@/store';
// @ts-ignore
import Storage from 'vue-ls';
import App from "./App.vue";
import routes from "./routes";
// import startQiankun from "./micro";
import "./assets/styles/locale.antd.css";
// @ts-ignore
import SSO from './cas/sso.js';

import axios from "axios"
import store from './store'
import './assets/icons' // icon
import './assets/icon/iconfont.css'
 axios.defaults.baseURL = setting.api_root; //网关
// axios.defaults.baseURL = 'http://192.168.31.89:8691'; //薛一凡
// axios.defaults.baseURL = 'http://192.168.31.177:8691'; //梦梦

import {ACCESS_TOKEN,USER_NAME,USER_INFO} from './store/commonExport';
import {addGlobalUncaughtErrorHandler,addErrorHandler} from "qiankun";
Vue.use(VueRouter);
Vue.use(Antd);
Vue.use(Storage)
Vue.config.productionTip = false;
// 为 Angular 微应用所做的 zone 包注入
// 如果没有 Angular 微应用，请删除这行代码
// import "zone.js/dist/zone";

/**
 * 注册路由实例
 * 即将开始监听 location 变化，触发路由规则
 */
const router = new VueRouter({
  mode: "history",
  routes,
});
router.beforeEach((to,from,next)=>{
    console.log(to,from,'to,from,');
    // 全局守卫,如果这个路径和   菜单中的完全一样，判断顶部菜单有没有，
    //如果有，更新store中数据,并存到本地，没有，先和sidemenu对比，
    //有完全一样的就添加，没有完全一样的就忽略
    console.log(to.path,'to.path');
    // 获取侧边菜单
    let sidemenus:any = sessionStorage.getItem('menu')
        sidemenus = JSON.parse(sidemenus)
    if(sidemenus && sidemenus.length > 0){
      console.log(sidemenus,'sidemenus');
      let flag:any =  sidemenus.find((i:any) => i.path == to.path )
      if(flag){
        sessionStorage.setItem('selectKey',flag.key)
        store.commit('updataCurrentMenus', flag.key)
        let topmenus:any = sessionStorage.getItem('menus')
        topmenus = JSON.parse(topmenus)
        if(topmenus){
          let flagtop:any =  topmenus.find((i:any) => i.path == to.path )
          console.log(flagtop,'flagtop', flag );
          if(!flagtop && flag.path !== '/home'){
            // 存到本地
            topmenus.push(flag)
            sessionStorage.setItem('menus',JSON.stringify(topmenus))
          }
        }
      }
    }
    next();
})
// 在 request 拦截器实现
axios.interceptors.request.use(
  config => {
    console.log('发送请求',config)
    // config.withCredentials = true // 允许携带token ,这个是解决跨域产生的相关问题
    // config.timeout = 6000
    let token = sessionStorage.getItem('token')
    // let csrf = store.getters.csrf
    if (token) {
      config.headers = {
      'ACCESS_TOKEN': token,
      // 'Content-Type': 'application/x-www-form-urlencoded'
    }
    }
    return config
  },
  error => {
    store.commit('hideLoading')
    return Promise.reject(error)
  }
)




// new Vue({
//   router,
//   store,
//   render: (h) => h(App),
// }).$mount("#main-app");

SSO.init(() => {
  main();
});
function main() {
  new Vue({
    router,
    store,
    mounted () {
      store.commit('SET_NAME',  (Vue as any).ls.get(USER_NAME))
      store.commit('SET_INFO',  (Vue as any).ls.get(USER_INFO))
      // store.commit('SET_MULTI_PAGE',Vue.ls.get(DEFAULT_MULTI_PAGE,true))
    },
    render: h => h(App)
  }).$mount('#main-app')

//   new Vue({
//     router,
//     render: (h) => h(App),
//   }).$mount("#main-app");
}
// startQiankun();

//
addGlobalUncaughtErrorHandler((event: Event | string) => {
    console.log(event,'event')
    let url = ''
    // @ts-ignore
    if ("target" in event) {
        // @ts-ignore
        url = event.target.location.origin
    }
    // @ts-ignore
    if (("reason" in event) && event.reason.stack === 'TypeError: Failed to fetch') {
       const a = document.getElementById('frame')
        const temp = `
                <div style="text-align: center;font-size: 20px;padding: 30px">
                        <p>子应用加载失败，请检查应用是否可运行</p>
                <!--        <div style="background-image: url('https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.zcool.cn%2Fcommunity%2F01cea05755a4d06ac72525ae9cadcd.jpg%40900w_1l_2o_100sh.jpg&refer=http%3A%2F%2Fimg.zcool.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1627096854&t=8d4ef93f8cbf380fe2a742e8c485baea');width: 100%;background: no-repeat "></div>-->
                        <img src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.zcool.cn%2Fcommunity%2F01cea05755a4d06ac72525ae9cadcd.jpg%40900w_1l_2o_100sh.jpg&refer=http%3A%2F%2Fimg.zcool.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1627096854&t=8d4ef93f8cbf380fe2a742e8c485baea"/>
                </div>        
               `
        // @ts-ignore
        a.innerHTML=temp;
        // @ts-ignore
        // message.error("子应用加载失败，请检查应用是否可运行");
        // setTimeout(function(){
        //     window.open(url,'_self')
        // },2000);
    }
    // router.push('/home')


})
// addGlobalUncaughtErrorHandler(err => {
//     console.log(err);
// });
