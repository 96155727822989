<template>
  <!-- 侧边菜单 -->
  <div class="cns-menu-side">
    <a-menu mode="inline" theme="light" :selectedKeys="[selectedKeys]" :inlineCollapsed="collapsed">
      <a-menu-item v-for="item in sideMenus" :key="item.key" @click.capture="changeMenu(item)">
        <router-link :to="{path: item.path.indexOf('http')!== -1 ? '/home' : item.path}">
          <span>
            <!--<a-icon :type="item.icon"/>-->
            <template v-if="!collapsed">
              <svg-icon :icon-class="item.icon"/>
              <span style="padding-left: 5px;">{{item.title}}</span>
            </template>
            <template v-else>
              <svg-icon v-if="item.title!=='首页'" :icon-class="item.icon" style="font-size: 1.2rem"/>
              <span v-if="item.title==='首页'" style="margin-left: -5px" >{{item.title}}</span>
              <span v-else style="padding-left: 5px;color: #fff" >{{item.title}}</span>
            </template>

          </span>
        </router-link>
      </a-menu-item>
    </a-menu>
  </div>
</template>

<script>
import axios from "axios"
import actions from '@/shared/actions';
import { loadMicroApp } from 'qiankun';
import store  from '@/store';
import {ACCESS_TOKEN, logout, UI_CACHE_DB_DICT_DATA} from "@/store/commonExport";
import Vue from "vue";
export default {
  name:'sideMenu',
  props:['sideMenus','collapsed',],
  data() {
    return {
      selectedKeys:'Home',
      microApps:[]
    };
  },
  watch: {},
  created(){
    this.getsideMenusData()
  },
  mounted(){
    actions.onGlobalStateChange((state, prevState) => {
      // state: 变更后的状态; prevState: 变更前的状态
      // console.log("主应用观察者：token 改变前的值为 ", prevState);
      // console.log("主应用观察者：登录状态发生改变，改变后的 token 的值为 ", state);
    })
  },
  methods: {
  // 获取侧边栏
    async getsideMenusData(){
      const res = await axios.get('/web/authmanage/api/menus/application')
      if(res.status !== 200){
        console.log('获取侧边菜单栏数据失败');
        return false
      }else {
        res.data.map((item)=>{
          return(
            this.microApps.push({
              name: item.componentName,
              entry: item.applicationPath,
              container: "#frame",
              activeRule: item.path
            })
          )
        })
        sessionStorage.setItem('microApps',JSON.stringify(this.microApps))
      }
    },
    changeMenu(item) {
      console.log('侧边点击事件',item,item.path.indexOf('http') !== -1);
      if(item.key == 'Home'){
        return false
      }
      if(item.path.indexOf('http') !== -1){
        window.open(item.path)
      }else{
        // Promise.all([store.commit('showLoading')]).then((result) => {
        //   const { key,id } = item;
        //   const microApp = this.microApps.find(item => key === item.name);
        //   actions.setGlobalState({id})
        //   this.selectedKeys = key //侧边显示的一直是首页
        //   sessionStorage.setItem('menusId',id)
        //   sessionStorage.setItem('selectKey',key)
        //   // 判断，如果顶部菜单有，则直接跳，没有则添加
        //   let menusflag =  this.$store.state.home.menus.find(item =>  key == item.key);
        //   if(!menusflag){
        //     this.$store.commit('updataMenus',item)
        //   }
        //   // 当前菜单
        //   // this.$store.commit('updataCurrentMenus',key)
        //   if(microApp){
        //     loadMicroApp(microApp,{
        //       singular:false,
        //     })
        //     // Promise.all([loadMicroApp(microApp,{ singular:false })]).then((result) => {
        //       setTimeout(function(){
        //         store.commit('hideLoading')
        //       },3000);
        //     // }).catch((error) => {
        //     //   console.log(error)
        //     // })
        //   }
        // }).catch((error) => {
        //   console.log(error)
        // })


        store.commit('showLoading')
        const { key,id } = item;
        const microApp = this.microApps.find(item => key === item.name);
        actions.setGlobalState({id})
        this.selectedKeys = key //侧边显示的一直是首页
        sessionStorage.setItem('menusId',id)
        sessionStorage.setItem('selectKey',key)
        // 当前菜单
        this.$store.commit('updataCurrentMenus',key)
        if(microApp){
          loadMicroApp(microApp,{
            singular:false,
          })
          // 判断，如果顶部菜单有，则直接跳，没有则添加
          let menusflag =  this.$store.state.home.menus.find(item =>  key == item.key);
          if(!menusflag){
            this.$store.commit('updataMenus',item)
          }
          // Promise.all([loadMicroApp(microApp,{ singular:false })]).then((result) => {
            setTimeout(function(){
              store.commit('hideLoading')
            },3000);

        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
// 侧边菜单
.cns-menu-side {
  height: 100%;
  padding-bottom:40px;
    -webkit-touch-callout:none;  /*系统默认菜单被禁用*/
    -webkit-user-select:none; /*webkit浏览器*/
    -khtml-user-select:none; /*早期浏览器*/
    -moz-user-select:none;/*火狐*/
    -ms-user-select:none; /*IE10*/
    user-select:none;
  .cns-menu:not(.cns-menu-horizontal) .cns-menu-item-selected {
    border-radius: 0;
  }
  .cns-menu-item {
    width: 100%;
    color: #222;
    height: 40px;
    line-height: 40px;
    padding-left:30px!important;
  }
  .cns-menu-item-selected, .cns-menu-submenu-popup .cns-menu-item-selected {
    background-color: #fff;
  }
  .cns-menu,
  .cns-menu-inline,
  .cns-menu-sub{
    display: block!important;
  }
  .cns-menu-sub{
    display: block!important;
  }
  .cns-menu:not(.cns-menu-horizontal) .cns-menu-item-selected {
    background-color: none;
    // background-color: #fff;
    border-radius: 0;
  }
  .cns-menu:not(.cns-menu-horizontal) .cns-menu-item-selected {
    background-image: linear-gradient(to right, #D9E6FF, #fff);
    // background: #447FFB;
    color: #1660FD;
  }

  .cns-menu-item{
    border-radius: 0;
    margin-top:0px;
  }
  .cns-menu-side .cns-menu:not(.cns-menu-horizontal) .cns-menu-item-selected{
    background-image: linear-gradient(to right, red , yellow);
  }
}
.cns-menu,
  .cns-menu-inline,
  .cns-menu-sub{
    display: block!important;
  }
</style>

