
import actions from '@/shared/actions';
import store  from '@/store';
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { loadMicroApp } from 'qiankun';

type MenuItem = {
  key: string;
  title: string;
  icon?: string;
  path: string;
  id:string;
  children?: MenuItem[];
};

@Component
export default class Menu extends Vue {
  @Prop({ type: Array, default: [] })
  menus!: MenuItem[];

  @Watch("$route.path")
  onPathChange() {
    this._initMenus();
  }

  defaultMenus:object = {
    title: "首页",
    path: "/home",
    key:"Home"
  };
  selectKey: string = "";
  currentMicroApp: any = null;
  // path: object = {};
  created() {
    this._initMenus();
  /*  window.addEventListener('load',()=>{
      console.log('load')
      console.log('刷新完成')
      // 将selectKey存入
      sessionStorage.setItem('selectKey',this.$store.state.home.currentMenus)
      let microApps:any = sessionStorage.getItem("microApps")
      let selectKey:any = sessionStorage.getItem('selectKey')
      const microApp = JSON.parse(microApps).find((i: any) => selectKey===i.name);
      if(microApp){
          if(this.currentMicroApp){
              // 卸载子应用
              console.log('卸载子应用')
              console.log(this.currentMicroApp)
              this.currentMicroApp.unmount();
          }
          this.currentMicroApp = loadMicroApp(microApp,{ singular:false })
      }
    })*/
  }
  mounted(){
    if(typeof sessionStorage.getItem("selectKey") == 'string'){
      this.selectKey = sessionStorage.getItem("selectKey") as string
    }
    //  如果本地有菜单数据的值，直接取本地值给vuex中
    if(typeof sessionStorage.getItem("menus") == 'string'){
        var menuSession = sessionStorage.getItem("menus")
        this.$store.commit('getMenus',eval(menuSession as string) )
    }
    if(typeof sessionStorage.getItem("selectKey") == 'string'){
          var current = sessionStorage.getItem("selectKey")
          console.log(current,'current');
          this.$store.commit('updataCurrentMenus', current)

          let microApps:any = sessionStorage.getItem("microApps")
          let selectKey:any = sessionStorage.getItem('selectKey')
          const microApp = JSON.parse(microApps).find((i: any) => selectKey===i.name);
          if(microApp){
              if(this.currentMicroApp){
                  // 卸载子应用
                  console.log('卸载子应用')
                  console.log(this.currentMicroApp)
                  this.currentMicroApp.unmount();
              }
              this.currentMicroApp = loadMicroApp(microApp,{ singular:false })
              console.log(333443334)
              console.log( this.currentMicroApp)
          }
      }
  }
  private _initMenus() {
    console.log('this.$store.state.home.sideMenus',this.$store.state.home.sideMenus);
    const currentMenu = this._findCurrentMenu(
      this.$store.state.home.sideMenus, //传入的参数为完整的菜单栏
      this.$route.path
    ) as MenuItem;
    if (!currentMenu) return;
    const {key} = currentMenu
    this.selectKey = key;
    console.log('this.selectKey',this.selectKey);
  }

  private _findCurrentMenu(
    menus: MenuItem[],
    currentPath: string
  ): MenuItem | null {
    for (let i = 0; i < menus.length; i++) {
      const menu = menus[i];
      const { path } = menu;
      if (path === currentPath) return menu;
      const currentMenu = this._findCurrentMenu(
        menu.children || [],
        currentPath
      );
      if (currentMenu) return currentMenu;
    }
    return null;
  }

  /**
   * 切换菜单
   */
  private changeMenu(item: MenuItem) {
    store.commit('showLoading')
    let microApps:any = sessionStorage.getItem("microApps")
    const microApp = JSON.parse(microApps).find((i: any) => item.key===i.name);
    if(microApp){
        if(this.currentMicroApp){
            // 卸载子应用
            console.log('卸载子应用')
            console.log(this.currentMicroApp)
            this.currentMicroApp.unmount();
        }
      this.currentMicroApp = loadMicroApp(microApp,{ singular:false })
    }
    this.$store.commit('updataCurrentMenus',item.key)
    const { key,id} = item;
    actions.setGlobalState({id})
    sessionStorage.setItem('menusId',item.id)
    sessionStorage.setItem('selectKey',item.key)
    setTimeout(function(){
      store.commit('hideLoading')
    },3000);
  }

  // 删除菜单
  private deleteMenuItem(item: MenuItem){
    let microApps:any = sessionStorage.getItem("microApps")
    // 删除列表对应项
    var i = this.$store.state.home.menus.indexOf(item)
    let selectKey = sessionStorage.getItem('selectKey')
    // 判断当前删除的item 是否就是点击的target
    if(item.key == selectKey){
      if(i == 0){
        // 跳到首页
        this.$store.commit('updataCurrentMenus','Home')
        sessionStorage.setItem('selectKey','Home')
        this.$router.push('/home')
      }else if (i > 0){
        this.$store.commit('updataCurrentMenus',this.$store.state.home.menus[i-1].key)
        sessionStorage.setItem('selectKey',this.$store.state.home.menus[i-1].key)
        this.$router.push(this.$store.state.home.menus[i-1].path)
      }
      let selectKey: any = sessionStorage.getItem('selectKey')
      const microApp = JSON.parse(microApps).find((i: any) => selectKey.includes(i.name));
      if(microApp){
          if(this.currentMicroApp){
              // 卸载子应用
              console.log('卸载子应用')
              console.log(this.currentMicroApp)
              this.currentMicroApp.unmount();
          }
        this.currentMicroApp = loadMicroApp(microApp,{ singular:false })
      }
    }
    this.$store.commit('deleteMenus',i)
  }
}
