window._CONFIG = {};
window._CONFIG['casPrefixUrl'] = 'http://123.139.156.9:9223/cas';//单点登录
// @ts-ignore
module.exports = {
    api_root: "http://123.139.156.9:9221",  //网关
    //   api_root: "http://192.168.31.206:8691",  // 权限
  VUE_APP_CONNECTION : {
        host: '123.139.156.9',
        port:8083,
        endpoint:'/mqtt',
        clean: "true",
        connectTimeout:4000,
        reconnectPeriod:4000,
        username:'admin',
        password:'admin'
      }
}

