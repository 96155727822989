import Vue from 'vue';
import Vuex from 'vuex';

// import app from './modules/app';
// import user from './modules/user';
// import permission from './modules/permission';
// import enhance from './modules/enhance';
// import online from './modules/online';
// import getters from './getters';
import {ACCESS_TOKEN,USER_NAME,USER_INFO,UI_CACHE_DB_DICT_DATA,getAction,logout} from './commonExport';
import actions from '@/shared/actions';

Vue.use(Vuex)

//创建VueX对象
const store = new Vuex.Store({
  modules: {
    home:{
      state: {
        LOADING: false,
        // 顶部菜单栏
        menus:[],
        sideMenus:[{
          icon:'home',
          title: "首页",
          path: "/home",
          key:"Home"
        },],
        currentMenus:'Home',
        microApps:[],
        // 常用工具缓存
        toolItems:[]
      },
      mutations: {
        showLoading(state){
          state.LOADING = true
        },
        hideLoading (state) {
          state.LOADING = false
        },
        updataMicroApps(state,data){
          state.microApps.push(data)
        },
        // 侧边栏菜单数据，要传给主菜单使用
        updataSideMenus(state,data){
          state.sideMenus = data
        },

        // 更新顶部菜单
        updataMenus(state,data){
          state.menus.push(data)
          sessionStorage.setItem('menus',JSON.stringify(state.menus))
        },

        // 当前菜单
        updataCurrentMenus(state,data){
          state.currentMenus = data
        },

        // 页面刷新，重新获取菜单从本地
        getMenus(state,data){
          state.menus = data
        },

        // 删除顶部菜单
        deleteMenus(state,i){
          state.menus.splice(i,1);
          // 将删除后的菜单存到本地
          sessionStorage.setItem('menus',JSON.stringify(state.menus))
        },
        // 更新常用工具
        updataToolItems(state,data){
          state.toolItems = data
        },
      },
      actions: {
        aupdataMenus(context,payload){
          console.log('异步数据：menus');
          // 发请求，获取menus中的数据
        }
      },
      getters: {},
    }
  },
  state: {
    token: '',
    username: '',
    email: '',
    realname: '',
    tenantid:'',
    welcome: '',
    avatar: '',
    permissionList: [],
    info: {}
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, { username, realname, welcome }) => {
      state.username = username
      state.realname = realname
      state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_EMAIL: (state, email) => {
      state.email = email
    },
    SET_PERMISSIONLIST: (state, permissionList) => {
      state.permissionList = permissionList
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_TENANT: (state, id) => {
      state.tenantid = id
    },

  },
  actions: {
    // CAS验证登录
    ValidateLogin({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        getAction("/web/authmanage/auth/userInfo",userInfo).then(response => {
          console.log("----cas 登录--------",response);
          if(response.status == 200){
            const  token = response.headers.refresh_token
            actions.setGlobalState({token})

            const result = response.data
            const header = response.headers
            const userInfo = result.principal
            Vue.ls.set(ACCESS_TOKEN, header.refresh_token, 7 * 24 * 60 * 60 * 1000)
            Vue.ls.set(USER_NAME, userInfo.nickName, 7 * 24 * 60 * 60 * 1000)
            Vue.ls.set(USER_INFO, userInfo, 7 * 24 * 60 * 60 * 1000)
            commit('SET_TOKEN', header.refresh_token)
            commit('SET_INFO', userInfo)
            sessionStorage.setItem('token',header.refresh_token)
            sessionStorage.setItem('userName',userInfo.nickName)
            sessionStorage.setItem('id',userInfo.id)
            sessionStorage.setItem('email',userInfo.email)
            // commit('SET_NAME', { username: userInfo.username,realname: userInfo.realname, welcome: welcome() })
            // commit('SET_NAME', { username: userInfo.username,realname: userInfo.realname, welcome: welcome() })
            commit('SET_EMAIL', userInfo.email)
            commit('SET_AVATAR', userInfo.avatar)
            resolve(response)
          }else{
            resolve(response)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 登出
    Logout({ commit, state }) {
      return new Promise((resolve) => {
        let logoutToken = sessionStorage.getItem('token')
        console.log('logoutToken: '+ logoutToken)
        //单点退出
        logout('/web/authmanage/auth/logout',logoutToken).then((response) => {
          console.log('response33',response);
          commit('SET_TOKEN', '')
          commit('SET_PERMISSIONLIST', [])
          Vue.ls.remove(ACCESS_TOKEN)
          Vue.ls.remove(UI_CACHE_DB_DICT_DATA)
          sessionStorage.clear()
          var sevice = "http://"+window.location.host+"/";
          var serviceUrl = encodeURIComponent(sevice);
          window.location.href = window._CONFIG['casPrefixUrl']+"/logout?service="+serviceUrl;
          //resolve()
        }).catch((err) => {

          var sevice = "http://"+window.location.host+"/";
          var serviceUrl = encodeURIComponent(sevice);
          window.location.href = window._CONFIG['casPrefixUrl']+"/logout?service="+serviceUrl;
          console.log(err);
          resolve()
        })
      })
    },

  },
  getters: {},
  // getters,
})

export default store
