<template>
  <a-layout id="components-layout-demo-custom-trigger" class="home-container" >
    <a-layout-sider v-model="collapsed" :trigger="null" collapsible theme="dark" :style="{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0 }" style="border-top:1px solid #fff">
      <a-button class="foldToggle" @click="() => (collapsed = !collapsed)" :style="{width:(collapsed ? '80px' : '200px')}">
        <a-icon class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'" />
      </a-button>
      <!-- 侧边栏 -->
      <div class="cns-side-menu" style="width:100%;padding-bottom:60px;">
        <side-menu :sideMenus="sideMenus" :collapsed='collapsed'/>
      </div>
    </a-layout-sider>
    <a-layout :style="{marginLeft:(collapsed ? '80px' : '200px')}">
      <a-layout-content :style="{ margin: '24px 16px', background: 'transparent', minHeight: '280px' }" style="display:flex">
        <!-- 中间 -->
        <div class="middle" style="flex:3;">

        <!-- 第一行 常用工具-->
          <a-card title="常用工具">
            <!-- 右侧设置 -->
            <!-- <a slot="extra" href="#">
              <a-icon type="setting" theme="filled" />
            </a> -->

            <!-- 常用工具不为空 -->
            <a-row v-if="this.toolItems.length !== 0" class="toolBox">
              <a-col :xs="6" :sm="6" :md="6" :lg="6" :xl="3"  v-for="(item,index) in toolItems" :key="index" @click="updataMenus(item)">
                <router-link :to="{path: item.path.indexOf('http')!== -1 ? '/home' : item.path}" class="toolContent">
                  <span :style="{background: colorArr[index%6]}" class="toolTop" style="position: relative;">
                    <!-- 删除 -->
                    <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" @confirm="confirm(item.id)">
                      <template slot="title">
                        <p>警告提示</p>
                        <p>确定要删除常用工具 “{{item.title}}” 吗？</p>
                      </template>
                      <span class="toolDelete" @click.stop.prevent="deleteTool(item.id)">
                        <a-icon type="close" style="font-size:10px;color:#fff;"/>
                      </span>
                    </a-popconfirm>
                    <span class="iconfont icon-ruanjian-moren" style="font-size:26px;color:#fff;"></span>
                  </span>
                  <span style="color:#222;;margin-top:10px">{{item.title }}</span>
                </router-link>
              </a-col>
              <!-- +号  添加更多应用-->
              <a-col :xs="6" :sm="6" :md="6" :lg="6" :xl="3" v-if="this.toolItems.length <= 7">
                <div class="toolContent" @click="showAddToolModal">
                  <span class="toolTop addTool">
                    <a-icon type="plus" style="font-size:26px;color:#447FFB;vertical-align: middle;line-height: 60px;"/>
                  </span>
                  <span style="color:#222;margin-top:10px">新增</span>
                </div>
              </a-col>
            </a-row>
            <!-- 常用工具为空 -->
            <a-row v-else style="text-align:center" class="toolBox">
              <!-- +号  添加更多应用-->
              <a-col :xs="6" :sm="6" :md="6" :lg="6" :xl="3">
                <div class="toolContent" @click="showAddToolModal">
                  <span class="toolTop addTool">
                    <a-icon type="plus" style="font-size:26px;color:#447FFB;vertical-align: middle;line-height: 60px;"/>
                  </span>
                  <span style="color:#222;margin-top:10px">新增</span>
                </div>
              </a-col>
            </a-row>

            <!-- 新增功能的弹框 -->
            <a-modal title="新增常用工具" :visible="visible" @ok="handleOk" :bodyStyle="toolModalStyle"
              @cancel="handleCancel" >
              <p style="margin-bottom:30px">请选择要添加的常用工具</p>
              <a-select v-model="value" style="width: 100%" placeholder="请选择工具" >
                <a-select-option  :value="item.id" :label="item.title" v-for="(item,index) in toolMenus" :key="index">
                  <a-icon :type="item.icon"/> {{item.title}}
                </a-select-option>
              </a-select>
              <div slot="footer">
                <a-button @click="handleOk" :loading="addtoolload">添加</a-button>
                <a-button @click="handleCancel">取消</a-button>
              </div>
            </a-modal>
          </a-card>

        <!-- 第二行数据 流程处理事项 -->
          <a-card title="流程处理事项" style="margin:16px 0;" class="flowhandle" :loading="loadinglc">
            <a-radio-group size="small" slot="extra" @change="changelc" v-model="lcindex">
              <a-radio-button value="a">  待审核 </a-radio-button>
              <a-radio-button value="b">  已审核 </a-radio-button>
              <a-radio-button value="c"> 我的申请 </a-radio-button>
            </a-radio-group>

            <a-collapse expandIconPosition="right" :bordered="false">
              <a-collapse-panel :key="index" v-for="(item,index) in activitydata" >
                <span slot="header" style="font-weight:700;color:#222">
                  <img src="../../assets/images/process.png" alt="">
                  {{item.modelName}}
                </span>
                <a-table :bordered="false" :pagination="false" :data-source="item.dataList" :showHeader="false" >
                  <a-table-column key="applyThing" data-index="applyThing">
                    <template slot-scope="text">
                      <span style="font-weight:700;color:#222">{{text}}</span>
                    </template>
                  </a-table-column>
                  <a-table-column key="createBy" data-index="username">
                    <template slot-scope="text">
                      <span>申请人: {{text}}</span>
                    </template>
                  </a-table-column>
                  <a-table-column key="applyTime" data-index="applyTime">
                    <template slot-scope="text">
                      <span>申请时间: {{text}}</span>
                    </template>
                  </a-table-column>
<!--                  <a-table-column key="operation" title="operation">-->
<!--                    <template slot-scope="text">-->
<!--                      <span style="color: #1660FD;">详情</span>-->
<!--                      <span style="display:none">{{text}}</span>-->
<!--                    </template>-->
<!--                  </a-table-column>-->
                </a-table>
                <div class="jump" v-if="lcindex == 'c'" @click="updataMenus(liuchengitem)"><router-link :to="{path: liuchengitem.path+'/system/myApplyList'}">查看更多>></router-link></div>
                <div class="jump" v-if="lcindex == 'b'" @click="updataMenus(liuchengitem)"><router-link :to="{path: liuchengitem.path+'/system/approvalEndList'}">查看更多>></router-link></div>
                <div class="jump" v-if="lcindex == 'a'" @click="updataMenus(liuchengitem)"><router-link :to="{path: liuchengitem.path+'/system/notApprovalList'}">查看更多>></router-link></div>
                <span slot="extra" @click="handleClick" style="position: absolute;
                  right: 47px; top: 50%; transform: translateY(-50%)">
                  已提交审核<i style="font-style: normal;color: #1660FD;font-weight: bold;"> {{item.totalNum}} </i>条
                </span>
              </a-collapse-panel>
            </a-collapse>
          </a-card>
        </div>

        <!-- 右边 -->
        <div class="side-right">
          <a-card title="消息通知" style="margin:0 16px 0;flex:auto">
            <template #extra><a-button type="link" block @click="getMessageList({current:1,pageSize:10})">刷新</a-button></template>
<!--            <a slot="extra" href="#"> 全部标记为已读</a>-->
            <a-list item-layout="horizontal" :data-source="data.slice(0,5)">
              <a-list-item slot="renderItem" slot-scope="item" style="padding-left:0">
                <!--<a-list-item-meta   :description="item.messageContentScript" >-->
                <a-list-item-meta>
                  <!-- <a slot="" href="#"> </a> -->
                  <span slot="title" v-if="item.redirectParams" style="color:#1868fd;">
                    <div href="#" @click.capture="updataMenus(JSON.parse(item.redirectParams).pc.apply)">
                      <router-link :to="JSON.parse(item.redirectParams).pc.apply.path + JSON.parse(item.redirectParams).pc.path">
                        {{ item.title }}
                      </router-link>
                    </div>
                  </span>
                  <span v-else slot="title"> {{ item.title }} </span>
                  <a-avatar slot="avatar" :src="img" />
                </a-list-item-meta>
              </a-list-item>
            </a-list>
            <p @click="showModalmessage">
              <a href="#">更多通知</a>
            </p>
          </a-card>

        </div>

      </a-layout-content>
    </a-layout>
    <a-modal v-model="visibleModal" title="完善邮箱" @ok="modalHandleOk" okText="确认" cancelText="跳过">
      <p>您还未绑定邮箱，请先完善邮箱信息，可通过密保邮箱找回密码、验证身份</p>
      <a-input v-model="emailNumber" placeholder="请输入邮箱账号" />
      <div style="color: red;" v-show="isTip">请输入符合要求的邮箱账号</div>
    </a-modal>

    <a-modal width="90%" v-model="messagevisible" title="消息列表" :footer="null" :maskClosable="false" @cancel="closeModalmessage" >
      <a-table :dataSource="data" :columns="columns" @change="getMessageList" :pagination="ipagination">
        <span slot="action" slot-scope="text, record" v-if="record.redirectParams">
          <div href="#" @click.capture="updataMenus(JSON.parse(record.redirectParams).pc.apply)" style="color:#1868fd;">
            <router-link :to="JSON.parse(record.redirectParams).pc.apply.path + JSON.parse(record.redirectParams).pc.path">进入>></router-link>
          </div>
        </span>
      </a-table>
    </a-modal>
  </a-layout>
</template>

<script>
import Vue from 'vue';
import sideMenu from "@/components/side-menu/index.vue";
import axios from "axios"
import {message} from 'ant-design-vue'
import actions from '@/shared/actions';
import { loadMicroApp } from 'qiankun';
import img from '@/assets/images/process.png'
import AListItem from "ant-design-vue/es/list/Item";

Vue.use(message);
// import { Component, Vue } from "vue-property-decorator";
// 常用工具颜色数组
var colorArr = ['#447FFB','#F99B48','#4AC49E','#FFC431','#F44456','#A586FF']
const columns = [
  // {
  //   title: '标题',
  //   dataIndex: 'title',
  //   // width: 150,
  // },
  {
    title: '消息内容',
    dataIndex: 'title',
    // width: 150,
  },
  {
    title: '操作',
    dataIndex: 'action',
    // width: 150,
    align: 'center',
    scopedSlots: { customRender: 'action' },
  },
];
const tableData = [];
for (let i = 0; i < 100; i++) {
  tableData.push({
    key: i,
    name: `Edward King ${i}`,
    age: 32,
    address: `London, Park Lane no. ${i}`,
  });
}
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};
// 常用工具列表

export default {
  components: {
      AListItem,
    sideMenu,
  },
  data() {
    return {
      img,
      sideMenus:this.$store.state.home.sideMenus || [],
      microApps:sessionStorage.getItem("microApps") || [],          //侧边菜单栏数据
      collapsed: false,        //控制侧边菜单收起
      colorArr,                //常用工具的背景色数组
      toolItems: [],           //常用工具列表，
      toolMenus:[],            // 添加工具栏时数据
      addtoolload:false,
      visible: false,          // 控制新增常用工具的显示与隐藏
      visibleModal: false,      // 邮箱完善弹框
      messagevisible: false,      // 消息通知完善弹框
      isTip: false,      // 邮箱完善弹框
      emailNumber: '',      // 邮箱完善弹框
      value: undefined,         //新增常用工具弹框里面的单选框值
      toolModalStyle:{        //弹框样式
        height:'170px',
      },
      lcindex:'a',
      loadinglc: false,
      // 流程处理相关数据
      activitydata:[],
      currentMicroApp:null,
      data: [],
      tableData,
      columns,
      rowSelection,
      ipagination:{
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['5', '10'],
        showTotal: (total, range) => {
          return range[0] + "-" + range[1] + " 共" + total + "条"
        },
        // showQuickJumper: true,
        // showSizeChanger: true,
        total: 0
      },
      liuchengitem:{},
    }
  },
  watch: {},
  mounted(){
    var _beforeUnload_time = 0,_gap_time=0;
    window.onunload=function(){
    _gap_time=new Date().getTime()-_beforeUnload_time;
      if(_gap_time<=5){
        //浏览器关闭
        window.localStorage.clear()
      }
    }
    window.onbeforeunload=function(){
      _beforeUnload_time=new Date().getTime();
    }
    this.changelc()
  },
  async created(){
    console.log(23232323232323)
    document.title = '智慧校园统一服务平台'
    await this.getsideMenusData()
    if(this.$store.state.home.toolItems.length == 0){
      this.getToolData()
    } else {
      this.toolItems = this.$store.state.home.toolItems
    }
    // 判断是否绑定邮箱
    var item = sessionStorage.getItem("email");
    if(item && item != 'null'){
        this.visibleModal = false
    }else{
        this.visibleModal = true
    }
    await JSON.parse(sessionStorage.getItem("menusLocation")).map((item)=>{
      if(item.path == '/process'){
        this.liuchengitem={
          key: item.componentName,
          title: item.name,
          path: item.path,
          icon: item.icon,
          id:item.id,
        }
      }
    })
    await this.getMessageList({current:1,pageSize:10})

  },
  methods: {

    // 流程处理事项中的事件
    handleClick(event) {
      event.stopPropagation();
    },
    // 完善邮箱
    modalHandleOk(){
      const reg = new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"); //正则表达式
      if(this.emailNumber){
          this.isTip = false
          if(!reg.test(this.emailNumber)){ //正则验证不通过，格式不对
              this.isTip = true
              return ;
          }
      }else{
          this.isTip = true
          return ;
      }
      const data = {
          email: this.emailNumber
      }
      axios.post('/web/authmanage/api/users/updateEmail',data).then((res)=>{
          console.log(res)
          if(res.data.code !== 0){
              this.$message.error(res.data.content);
              return false;
          }else{
              this.$message.info('邮箱完善完成！');
              this.visibleModal = false
          }
      })
    },
    // 获取侧边栏
    async getsideMenusData(){
      this.toolMenus = []
      if(JSON.parse(sessionStorage.getItem('menu'))){
        this.sideMenus = JSON.parse(sessionStorage.getItem('menu'))
        this.sideMenus.forEach(item => {
          if(item.key != 'Home'){
            this.toolMenus.push(item)
          }
        })
      }else {
        const res = await axios.get('/web/authmanage/api/menus/application')
        console.log('侧边栏菜单数据',res);
        if(res.status !== 200){
          console.log('获取侧边菜单栏数据失败');
          return false
        }
        this.sideMenus=[
          {
            icon:'home',        //侧边菜单栏数据
            title: "首页",
            path: "/home",
            key:'Home',         //对应key
          }
        ]
        res.data.map((item)=>{
          return(
            this.sideMenus.push({
              key: item.componentName,
              title: item.name,
              path: item.path,
              icon: item.icon,
              id:item.id,
            })
          )
        })
        this.sideMenus.forEach(item => {
          if(item.key != 'Home'){
            this.toolMenus.push(item)
          }
        })
        sessionStorage.setItem('microApps',JSON.stringify(this.microApps))
        sessionStorage.setItem('menusLocation',JSON.stringify(res.data))
        sessionStorage.setItem('menu',JSON.stringify(this.sideMenus))
        this.$store.commit('updataSideMenus',this.sideMenus)
      }
    },

    // 获取常用工具数据,并将常用工具存到本地
    async getToolData(){
      let toolItemsTemp = []
      const res = await axios.get('/web/authmanage/api/commonMenu/find')
      console.log('常用工具',res);
      if(res.status !== 200){
        console.log('获取常用工具数据失败');
        return false
      }
      res.data.map((item)=>{
        return(
          toolItemsTemp.push({
            key: item.componentName,
            title: item.name,
            path: item.path,
            icon: item.icon,
            id:item.id,
          })
        )
      })
      this.toolItems = toolItemsTemp
      this.$store.commit('updataToolItems',this.toolItems)
    },

    // 常用工具的点击事件
    updataMenus(item){
      if(item.path.indexOf('http') !== -1){
        window.open(item.path)
      }else {
        let microApps = sessionStorage.getItem("microApps")
        const microApp = JSON.parse(microApps).find((i) => item.key===i.name);
        if(microApp){
          if(this.currentMicroApp){
              // 卸载子应用
              console.log('卸载子应用')
              console.log(this.currentMicroApp)
              this.currentMicroApp.unmount();
          }
         this.currentMicroApp =  loadMicroApp(microApp,{ singular:false })
        }
        console.log('常用工具点击事件',item);
        const { key,id } = item;
        actions.setGlobalState({id})
        sessionStorage.setItem('menusId',id)
        sessionStorage.setItem('selectKey',item.key)
        // 如果顶部菜单没有则添加到顶部
        let menusflag =  this.$store.state.home.menus.find(item =>  key == item.key);
        if(!menusflag){
          this.$store.commit('updataMenus',item)
        }
        this.$store.commit('updataCurrentMenus',key)
      }
    },

    // 删除常用工具
    deleteTool(id){
      console.log('删除常用工具message',id,this);
    },

    // 确认是否要删除该常用工具
    async confirm(id) {
      console.log('确定',id);
      // 发请求，删除该常用工具
      var parmas = {menuId:id}
      const res = await axios.delete('/web/authmanage/api/commonMenu/delete',{data:parmas} )
      console.log('删除该常用工具',res);
      if(res.status !== 200){
        console.log('删除常用工具数据失败');
        return false
      }
      this.$message.success('删除成功');
      this.getToolData()
    },

    // 新增常用工具相关事件
    showAddToolModal() {
      this.visible = true;
    },
    // 确定新增
    async handleOk(e) {
      this.addtoolload = true
      // 判断，如果常用工具列表已经有该工具，则不让添加
      let toolflag =  this.toolItems.find(item =>  this.value == item.id);
      if(toolflag){
        this.$message.warn('常用工具列表已经有该工具,不能重复添加');
      }else {
        let data = {menuId:this.value}
        const res = await axios.post('/web/authmanage/api/commonMenu/add',data)
        if(res.status !== 200){
          this.$message.error('添加常用工具失败！请重新添加');
          return false;
        }
        this.$message.success('添加成功');
        this.getToolData()
        // 关机弹框 清空数据
        this.visible = false
        this.value = undefined
      }
      this.addtoolload = false
    },
    // 关闭弹框
    handleCancel(e) {
      console.log('Clicked cancel button');
      this.visible = false;
      this.value = undefined
    },
    //获取消息通知
    getMessageList({current,pageSize}){
      this.ipagination.current = current
      this.ipagination.pageSize = pageSize
      const data = {
        pageNum:current,
        pageSize:pageSize,
        userFlag:sessionStorage.getItem('id'),
        userType:"2",
      }
      axios.post('/message/messageRecords/findPageList',data).then((res)=>{
        console.log(res,'oooooo')
        if(res.data.code !== '0'){
          this.$message.error(res.data.message);
          return false;
        }else{
          this.data = res.data.content.list
          this.ipagination.total = res.data.content.total
          // this.data.push(...res.data.content.list,...res.data.content.list,...res.data.content.list,...res.data.content.list,...res.data.content.list,...res.data.content.list,...res.data.content.list,...res.data.content.list,...res.data.content.list,...res.data.content.list,...res.data.content.list,...res.data.content.list,...res.data.content.list,...res.data.content.list,...res.data.content.list,...res.data.content.list,...res.data.content.list,...res.data.content.list)
        }
      })
    },
    showModalmessage () {
      this.messagevisible = true;
    },
    closeModalmessage () {
      this.messagevisible = false;
      this.getMessageList({current:1,pageSize:10})
    },
    // 待审核
    getIndexTaskList(){
      const parms ={
        userId :sessionStorage.getItem('id')
      }
      axios.post('/web/bigdataAct/activiti/activitiModelExtend/getIndexTaskList',parms).then((res)=>{
        console.log(res,776655)
        if(res.data.code === 200){
          this.activitydata = res.data.result
          this.loadinglc = false
        }else{
          this.$message.error(res.data.message);
          this.loadinglc = false
          return false;
        }
      })
    },
    // 已审核
    getIndexHistoryTask(){
      axios.get(`/web/bigdataAct/activiti/activitiModelExtend/getIndexHistoryTask?userId=${sessionStorage.getItem('id')}`).then((res)=>{
        if(res.data.code === 200){
          this.activitydata = res.data.result
          this.loadinglc = false
        }else{
          this.$message.error(res.data.message);
          this.loadinglc = false
          return false;
        }
      })
    },
    //我的申请
    getIndexMyApply(){
      axios.get(`/web/bigdataAct/activiti/activitiBusinessData/indexMyApply?applyUserId=${sessionStorage.getItem('id')}`).then((res)=>{
        if(res.data.code === 200){
          this.activitydata = res.data.result
          this.loadinglc = false
        }else{
          this.$message.error(res.data.message);
          this.loadinglc = false
          return false;
        }
      })
    },
    //切换流程
    changelc(){
      console.log(888776655)
      this.loadinglc = true
      this.activitydata = []
      if(this.lcindex == 'a'){
        this.getIndexTaskList()
      }else if(this.lcindex == 'b'){
        this.getIndexHistoryTask()
      }else if(this.lcindex == 'c'){
        this.getIndexMyApply()
      }
    }
  },
}
</script>
<style lang="less">
  .home-container {
    height: calc(100vh - 60px);
    .trigger {
      font-size: 18px;
      line-height: 64px;
      padding: 0 24px;
      cursor: pointer;
      transition: color 0.3s;
    }
    .trigger:hover {
      color: #1890ff;
    }
    .foldToggle{
      display: block;
      position: fixed;
      z-index: 1;
      bottom: 0px;
      height: 40px;
      left: 0;
      background: #447ffb;
      border: none;
      border-radius: 0;
      color:#fff;
    }
    .foldToggle:hover,
    .foldToggle:focus {
      color:#fff;
      background: #447ffb;
    }
    aside{
      padding:0;
    }
    .cns-affix {
      z-index: 0;
    }
    // 第一行常用工具
    .toolBox{
      .toolContent {
        display:flex;
        flex-direction:column;
        align-items: center;
        margin-bottom:15px;
      }
      .toolTop {
        width: 60px;
        height: 60px;
        text-align: center;
        border-radius: 50%;
        line-height: 60px;

        // 删除
        .toolDelete {
          position: absolute;
          top: 0;
          right: 0;
          display: block;
          width: 16px;
          height: 16px;
          line-height: 16px;
          border-radius: 50%;
          background: #000;
          opacity: 0.3;
        }
      }
      .addTool {
        border: 2px dashed #447FFB;
      }
    }

    // 流程处理事项
    .flowhandle{
      .cns-collapse-header .cns-collapse-arrow {
        right:20px!important;
        left:auto!important;
      }
      .cns-collapse-content > .cns-collapse-content-box {
        background: #F6F7F9!important;
        margin: 20px;
      }
      .cns-radio-button-wrapper-checked:first-child,
      .cns-radio-button-wrapper {
        border: none;
        border-radius: 0;
      }
      .cns-collapse > .cns-collapse-item > .cns-collapse-header {
        background: #fff!important;
        padding: 12px 0 12px 20px!important;
      }
      .cns-radio-button-wrapper:not(:first-child)::before {
        display: none;
      }
      .cns-radio-button-wrapper-checked  {
        box-shadow: -1px 0 0 0 #fff;
        border-bottom: 2px solid #1660FD!important;
        color:#1660FD;
      }
      .cns-radio-button-wrapper:first-child
      .cns-radio-button-wrapper:focus-within {
        outline: 0px solid rgba(24, 144, 255, 0.06)!important;
      }

      .jump{
        text-align:center;
        margin-top:20px;
        .cns-btn{
          color: #1660FD;
          border: none;
          background: transparent;
        }
      }
    }
    .cns-card-head-title,
    .cns-card-head-title {
      font-weight:700;
      color:#222;
    }
    // 右侧任务
    .side-right{
      float:left;
      flex:1;
      margin-left:16px;
      display:flex;
      flex-direction: column;
      height: 0px;
    }

  }
</style>
