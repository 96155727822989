import axios from 'axios';


export const ACCESS_TOKEN = 'Access-Token';
export const USER_NAME = 'Login_Username';
export const USER_INFO = 'Login_Userinfo';
export const UI_CACHE_DB_DICT_DATA = 'UI_CACHE_DB_DICT_DATA'
export const getAction = (url,parameter)=>{
    return axios({
        url: url,
        method: 'get',
        params: parameter
    })
}

export function logout(url,logoutToken) {
    return axios({
        // url: '/sys/logout',
        url: url,
        method: 'get',
        // headers: {
        //     'Content-Type': 'application/json;charset=UTF-8',
        //     'ACCESS_TOKEN':  logoutToken
        // }
    })
}




