<template>
  <div class="ChangePassword">
    <div class="pasBox">
        <h3>修改密码</h3>
        <h5>密码不能是admin, 必须大于6位,不能全是相同字符和顺序字符, 例如aaaaaaa,1234567</h5>
        <div style="margin-top: 20px;"></div>
        <a-form-model ref="ruleForm" :model="ruleForm" :rules="rules" v-bind="layout">
          <a-form-model-item  label="当前密码" prop="oldPass">
            <a-input-password v-model="ruleForm.oldPass" type="password" autocomplete="off" />
          </a-form-model-item>
          <a-form-model-item has-feedback label="新密码" prop="newPass">
            <a-input-password v-model="ruleForm.newPass" type="password" autocomplete="off" />
          </a-form-model-item>
          <a-form-model-item has-feedback label="新密码确认" prop="checkPass">
            <a-input-password v-model="ruleForm.checkPass" type="password" autocomplete="off" />
          </a-form-model-item>

          <a-form-model-item :wrapper-col="{ span: 14, offset: 8 }">
            <a-button type="primary" @click="submitForm('ruleForm')" style="padding:5px 20px">
              保 存
            </a-button>
            <a-button type="primary" @click="back" style="padding:5px 20px;margin-left:30px">
              返 回
            </a-button>
          </a-form-model-item>
        </a-form-model>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from "axios"
import store  from '@/store'

export default {
  components: {
  },
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'));
      } else if(value.length <= 6) {
          callback(new Error("新密码不能小于等于6位，请重新输入!"));
      }else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.newPass) {
        callback(new Error("两次输入结果不一致，请重新输入!"));
      } else if(value.length <= 6) {
          callback(new Error("新密码不能小于等于6位，请重新输入!"));
      }else{
          callback();
      }
    };
    return {
      ruleForm: {
        newPass: '',//新密码
        checkPass: '',
        oldPass: '',//原密码
      },
      rules: {
        newPass: [{ required: true, validator: validatePass, trigger: 'change' }],
        checkPass: [{ required: true, validator: validatePass2, trigger: 'change' }],
        oldPass: [{ required: true, message: '请输入当前密码', trigger: 'blur' }],
      },
      layout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 16 },
      },
    }
  },
  watch: {
  },
  mounted(){
  },
  created(){
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          // 发请求
          console.log(this.ruleForm);
          const res = axios.post('web/authmanage/api/users/updatePass',{
            oldPass:this.ruleForm.oldPass,
            newPass:this.ruleForm.newPass
          }).then((res)=>{
              if(res.data.code == -1){
                  this.$message.error(res.data.msg)
                  return false
              }
              this.$message.success('重置密码成功，请重新登录')
              store.dispatch('Logout').then(res => {
                  sessionStorage.removeItem('token')
                  sessionStorage.removeItem('userName')
              }).catch((err) => {
                  console.log('退出登录err',err);
              });
          }).catch(err=>{
              console.log(err)
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    back(){
      this.$router.go(-1)
    }

  },
}
</script>
<style lang="less" scoped>
.cns-frame-wrapper{
   background: #f5f6f7;
}
</style>
<style lang="less">
.ChangePassword {
  background: #f5f6f7;
  height: 100vh;
  h3 {
    font-weight: 700;
    text-align: center;
    font-size: 24px;
    margin-bottom: 40px;
  }
  .pasBox{
    width: 500px;
    margin: auto;
    padding-top:160px;
    .ant-input {
      width: 300px;
      height: 44px;
    }
    .ant-input-suffix {
      position: absolute;
      right: 5px;
      line-height: 44px;
    }
    .has-success.has-feedback .cns-form-item-children-icon,
    .has-warning.has-feedback .cns-form-item-children-icon,
    .has-error.has-feedback .cns-form-item-children-icon,
    .is-validating.has-feedback .cns-form-item-children-icon {
      right: -28px;
    }
  }
}
</style>
