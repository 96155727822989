import Vue from 'vue'
// @ts-ignore
// import { ACCESS_TOKEN } from "@/store/mutation-types"
// @ts-ignore
import store  from '@/store'
// import store from '@/store/user'
import {ACCESS_TOKEN}  from '@/store/commonExport'
import actions from '@/shared/actions';

/**
 * 单点登录
 */
const init = (callback) => {
  console.log("-------单点登录开始-------");
  actions.onGlobalStateChange((state, prevState) => {
    // state: 变更后的状态; prevState: 变更前的状态
    console.log("主应用观察者：token 改变前的值为 ", prevState);
    console.log("主应用观察者：登录状态发生改变，改变后的 token 的值为 ", state);
  });
  
  
  // let token = Vue.ls.get(ACCESS_TOKEN) || sessionStorage.getItem('token');
  let token = sessionStorage.getItem('token');

  console.log(token,'token');
  
  let st = getUrlParam("ticket");
  
  let sevice = "http://"+window.location.host+"/";
  if(token){
    actions.setGlobalState({token})
    loginSuccess(callback);
  }else{
    if(st){
      console.log('st',st);
      // loginSuccess(callback);
      validateSt(st,sevice,callback);
    }else{
      let serviceUrl = encodeURIComponent(sevice);
      window.location.href = window._CONFIG['casPrefixUrl']+"/login?service="+serviceUrl;
    }
  }
  
  console.log("-------单点登录结束-------");
};

const SSO = {
  init: init
};

function getUrlParam(paraName) {
  let url = document.location.toString();
  let arrObj = url.split("?");

  if (arrObj.length > 1) {
    let arrPara = arrObj[1].split("&");
    let arr;

    for (let i = 0; i < arrPara.length; i++) {
      arr = arrPara[i].split("=");

      if (arr != null && arr[0] == paraName) {
        return arr[1];
      }
    }
    return "";
  }
  else {
    return "";
  }
}
function validateSt(ticket,service,callback){
  let params = {
    ticket: ticket,
    service:service
  };
  store.dispatch('ValidateLogin',params).then(res => {
    //this.departConfirm(res)
   
    if(res.status == 200){
      loginSuccess(callback);
    }else{
      let sevice = "http://"+window.location.host+"/";
      let serviceUrl = encodeURIComponent(sevice);
      window.location.href = window._CONFIG['casPrefixUrl']+"/login?service="+serviceUrl;
    }
  }).catch((err) => {
    console.log(err);
    //that.requestFailed(err);
  });
}

function loginSuccess (callback) {
  callback();
}
export default SSO;
