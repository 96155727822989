import Home from "@/pages/home/index.vue"; //首页
import ChangePassword from "@/pages/home/ChangePassword.vue"; //修改密码页面

const routes = [
  {
    path: '',
    redirect: '/home'
  },
  {
    /**
     * path: 路径为 / 时触发该路由规则
     * name: 路由的 name 为 Home
     * component: 触发路由时加载 `Home` 组件
     */
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    // Change the password 修改密码
    /**
     * path: 路径为 / 时触发该路由规则
     * name: 路由的 name 为 Home
     * component: 触发路由时加载 `Home` 组件
     */
    path: "/ChangePassword",
    name: "ChangePassword",
    component: ChangePassword,
  }
];

export default routes;
