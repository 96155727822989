<template>
  <div class="loading">
    <!-- <img src="../assets/loading.gif" alt=""> -->
    <a-spin size="large" />
  </div>
</template>
 
<script>
  export default {
    name: 'LOADING',
    data () {
      return {}
    },
  }
</script>
<style scoped>
  .loading {
    position: fixed;
    top:0;
    left:0;
    bottom: 0;
    z-index:99999;
    width: 100%;
    height: 100%;
    background: rgba(187, 219, 219, 0.3);
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
  .loading .cns-spin,.cns-spin-lg,.cns-spin-spinning {
    width: 20px;
    height: 20px;
    margin:auto;
    position: fixed;
    top: 50%;
  }
</style>