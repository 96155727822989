
import { Component, Vue } from "vue-property-decorator";
// @ts-ignore
import store  from '@/store';
import Loading from './components/loading.vue';
import { mapState } from 'vuex';
import MainMenu from "@/components/menu/index.vue";
import {loadMicroApp} from "qiankun";
import mqtt from "mqtt";
import setting from './settings';
@Component({
  components: {
    MainMenu,
    Loading
  }
})

// 发请求获取相关的应用
export default class App extends Vue {
  /**
   * 菜单列表
   * key: 唯一 Key 值
   * title: 菜单标题
   * path: 菜单对应的路径
   */
  get LOADING(){
    return this.$store.state.home.LOADING
  }

  centerDialogVisible:boolean = true
  currentMicroApp: any = null
  client: unknown
  created(){
  }
  mounted(){
     this.$nextTick(()=>{
         var connection = setting.VUE_APP_CONNECTION
         const { host, port, endpoint, ...options } = connection
         const connectUrl = `ws://${host}:${port}${endpoint}`
         // 初始化
         this.initConnect(this.client,connectUrl,options)
     })
  }
  // 退出登录
  private confirm() {
      this.$confirm({
        title: '提示',
        content: '确定要退出登录吗？',
        okText: '确认',
        cancelText: '取消',
        onOk() {
          store.dispatch('Logout').then(res => {
          console.log(res,"yyy")
          sessionStorage.clear()
        }).catch((err) => {
            console.log('退出登录err',err);
        })},
        onCancel() {
          console.log('退出登录err');
        }
      })
    }
  private jump(){
    this.$router.push('/ChangePassword')
  }
  private initConnect(client,connectUrl,options) {
        if(!client){
            client = mqtt.connect(connectUrl, options)
        }
        this.client = client
        console.log(this.client)
        const userInfo = JSON.parse(JSON.stringify(this.$store.state.info))
       if(client != null){
           if(!(client.connected)){
               client.on("connect", (e) => {
                   console.log('连接成功!')
               });
           }
           client.on('error', error => {
               console.log('连接失败', error)
           })
           //  接收消息
           client.on("message", (topic, message) => {
               console.log('接收' + this.$route.path);
               if(topic == 'all'){
                   this.$message.success(`发给所有人的消息:${message}`)
               }else if(topic == userInfo.id){
                   this.$message.success(`发给您的消息:${message}`)
               }else{
                   this.$message.success(`发给${topic}的消息:${message}`)
               }
           })
       }
      this.doSubscribe(client,'all',{qos: 1}) //全部通知
      if(userInfo.id){
          this.doSubscribe(client,this.$store.state.info.id.toString(),{qos: 1}) //个人订阅
      }
    }
  //订阅主题
  private doSubscribe(client,topic,qos) {
      console.log('订阅',topic);
      client.subscribe(topic, qos, (error, res) => {
          if (error) {
              console.log('订阅消息失败', error)
              return
          }
          console.log('订阅消息', res)
      })
  }
  //取消订阅
  private doUnSubscribe(client,topic) {
        console.log('取消订阅',topic);
        client.unsubscribe(topic, error => {
            if (error) {
                console.log('取消订阅失败', error)
            }
        })
  }
  //断开连接
  private destroyConnection(client) {
    console.log('断开连接');
    if (client.connected) {
        try {
          client.end()
          console.log('已成功断开连接')
          this.$message.success('已成功断开连接')
        } catch (error) {
            console.log('Disconnect failed', error.toString())
            this.$message.success('断开连接失败')
        }
    }
  }
  //消息发布
  private doPublish(client,topic, payload, qos) {
    console.log(client)
    client.publish(topic, payload, qos, error => {
        if (error) {
            console.log('消息发布失败', error)
        }
    })
  }
}
